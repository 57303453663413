import "./Hero.scss";
import Button from "../Button/Button";
import rdImg from "../../assets/images/rd.jpg";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__container">
        <h2 className="hero__title">About Me</h2>
        <div className="hero__wrapper">
          <div className="hero__content">
            <img className="hero__content-img" src={rdImg} alt="rd pic" />
          </div>
          <div className="hero__section">
            <p className="hero__subtitle">
              <div className="hero__subtitle-text">
                Greetings! I'm{" "}
                <span className="hero__subtitle-name">Ralph Cabauatan.</span> My
                journey to coding began during my college internship as a{" "}
                <span className="hero__subtitle-name">Web Developer</span> at a
                software testing company. Since then, I've gained 5+ years of
                experience enhancing my skills through self-learning, hands-on
                projects and collaborating with teams of diverse backgrounds. In
                addition, I recently graduated from the{" "}
                <span className="hero__subtitle-name">
                  Software Engineering{" "}
                </span>
                program at BrainStation and I'm currently based in Toronto,
                Ontario.
              </div>
              <div className="hero__subtitle-text">
                I'm passionate about building web applications that live on the
                internet and I enjoy problem-solving using logic thinking to
                create intuitive and visually appealing projects that provide
                users a seamless experience.
              </div>
              <div className="hero__subtitle-text">
                I believe with hard work, perseverance and little bit of
                research, anything can be accomplished!
              </div>
            </p>
          </div>
        </div>
        <div className="hero__area">
          <h2 className="hero__area-title">My Skills</h2>
          <div className="hero__area-container">
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">Front End</h3>
              <p className="hero__skills-text">HTML/CSS</p>
              <p className="hero__skills-text">SASS</p>
              <p className="hero__skills-text">JavaScript</p>
              <p className="hero__skills-text">React</p>
              <p className="hero__skills-text">TypeScript</p>
              <p className="hero__skills-text">Next.js</p>
            </div>
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">Back End</h3>
              <p className="hero__skills-text">Node.js</p>
              <p className="hero__skills-text">Express.js</p>
              <p className="hero__skills-text">REST API</p>
              <p className="hero__skills-text">JWT</p>
              <p className="hero__skills-text">OAuth</p>
              <p className="hero__skills-text">Passport.js</p>
            </div>
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">Database</h3>
              <p className="hero__skills-text">MySQL</p>
              <p className="hero__skills-text">Knex.js</p>
              <p className="hero__skills-text">MongoDB</p>
              <p className="hero__skills-text">AWS</p>
            </div>
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle"> Tools</h3>
              <p className="hero__skills-text">Git</p>
              <p className="hero__skills-text">Jest</p>
              <p className="hero__skills-text">Jira</p>
              <p className="hero__skills-text">Heroku</p>
            </div>
          </div>
        </div>
        <div className="hero__button-container">
          <a href="https://drive.google.com/file/d/1AHIa-2btn2DENtfVODDVXhuWD0JxhP5A/view?usp=sharing">
            <Button label="View Resume" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Hero;
