import "./Contact.scss";
import Button from "../Button/Button";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact__container">
        <h2 className="contact__title">Contact</h2>
        <h3 className="contact__subtitle">Get In Touch.</h3>
        <p className="contact__subtext">Have questions? Leave a message :)</p>
        <div className="contact__wrapper">
          <form className="contact__form">
            <div className="contact__form-wrapper">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                required
              />
            </div>

            <div className="contact__form-wrapper">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                required
              />
            </div>

            <div className="contact__form-wrapper">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
                rows="5"
                required
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div className="contact__button">
        <Button label="Send" />
      </div>
    </div>
  );
};
export default Contact;
