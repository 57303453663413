import "./Main.scss";
import code from "../../assets/images/coding.jpg";
import tree from "../../assets/images/tree.jpg";
import { FaLinkedinIn, FaGithub, FaEnvelope } from "react-icons/fa";

const Main = () => {
  return (
    <div className="main">
      <div className="main__container">
        <div className="main__wrapper">
          <img className="main__code" src={code} alt="coding pic" />
          <img className="main__tree" src={tree} alt="tree pic" />
        </div>
        <div className="main__text">
          <div className="main__text-container">
            <h2 className="main__text-intro">Hi my name is,</h2>
            <h1 className="main__text-name">Ralph Cabauatan.</h1>
            <h2 className="main__text-role">Software Developer</h2>
            <h3 className="main__text-location">Based in Toronto</h3>
          </div>
          <div className="main__section">
            <button className="main__button">
              <div className="main__social-icons">
                <a
                  href="https://www.linkedin.com/in/ralphdaryn/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="mailto:ralphdarync@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope />
                </a>
                <a
                  href="https://github.com/ralphdaryn/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGithub />
                </a>
             
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;
