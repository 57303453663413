// import "./Projects.scss";
// import { useState } from "react";
// import bargainHunter from "../../assets/images/bargainhunter.jpg";
// import GIFmatch from "../../assets/images/GIFmatch.jpg";
// import solesearch from "../../assets/images/solesearch.jpg";

// import {
//   FaReact,
//   FaHtml5,
//   FaCss3Alt,
//   FaSass,
//   FaJs,
//   FaNode,
//   FaChevronLeft,
//   FaChevronRight,
//   FaCodeBranch,
// } from "react-icons/fa6";

// const projectsData = [
//   {
//     img: bargainHunter,
//     work: "Featured Project",
//     title: "Bargain Hunter",
//     caption:
//       "A web-scraper application that offers real-time price tracking, top deals and flexible price sorting with detailed product reviews to inform online products to secure the best discount.",
//     code: "https://github.com/ralphdaryn/bargain-hunter",
//   },
//   {
//     img: GIFmatch,
//     work: "Featured Project",
//     title: "GIF Match Game",
//     code: "https://github.com/ralphdaryn/GIFmatchgame",
//     caption:
//       "A memory card game reinvented with animated gifs, offering the option to select categories for a personalized gaming experience.",
//   },
//   {
//     img: solesearch,
//     work: "Featured Project",
//     title: "Sole Search",
//     code: "https://github.com/ralphdaryn/sole-search",
//     caption:
//       "A sneaker database application providing extensive details about various sneaker models, brands, release dates, and current market prices of your favourite brands.",
//   },
// ];

// const Projects = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const goToPrevSlide = () => {
//     setCurrentSlide((prevSlide) =>
//       prevSlide > 0 ? prevSlide - 1 : projectsData.length - 1
//     );
//   };

//   const goToNextSlide = () => {
//     setCurrentSlide((prevSlide) =>
//       prevSlide < projectsData.length - 1 ? prevSlide + 1 : 0
//     );
//   };

//   return (
//     <div className="projects">
//       <h2 className="projects__title">Latest Work</h2>
//       <div className="projects__container">
//         <button onClick={goToPrevSlide} className="projects__button">
//           <FaChevronLeft />
//         </button>
//         <div className="projects__wrapper">
//           {projectsData.map((project, index) =>
//             index === currentSlide ? (
//               <div key={index} className="projects__item">
//                 <img
//                   className="projects__image"
//                   src={project.img}
//                   alt={project.title}
//                 />
//                 <div className="projects__content">
//                   <p className="projects__work">{project.work}</p>
//                   <h2 className="projects__text">{project.title}</h2>
//                   <p className="projects__caption">{project.caption}</p>
//                   <p className="projects__language">
//                     <FaHtml5 />
//                     <FaCss3Alt />
//                     <FaSass />
//                     <FaJs />
//                     <FaReact />
//                     <FaNode />
//                   </p>
//                   <a
//                     href={project.code}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="projects__code"
//                   >
//                     View Code <FaCodeBranch />
//                   </a>
//                 </div>
//               </div>
//             ) : null
//           )}
//         </div>
//         <button onClick={goToNextSlide} className="projects__button">
//           <FaChevronRight />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Projects;

import "./Projects.scss";
import { useState } from "react";
import bargainHunter from "../../assets/images/bargainhunter.jpg";
import GIFmatch from "../../assets/images/GIFmatch.jpg";
import soleSearch from "../../assets/images/solesearch.jpg";

import {
  FaReact,
  FaHtml5,
  FaCss3Alt,
  FaSass,
  FaJs,
  FaNode,
  FaChevronLeft,
  FaChevronRight,
  FaCodeBranch,
} from "react-icons/fa6";

const projectsData = [
  {
    img: bargainHunter,
    work: "Featured Project",
    title: "Bargain Hunter",
    caption:
      "An e-commerce application that offers real-time price tracking, top deals and flexible price sorting with detailed product reviews to secure the best discount available utilizing the Oxylabs API web-scraper.",
    code: "https://github.com/ralphdaryn/bargain-hunter",
    languages: [FaHtml5, FaCss3Alt, FaSass, FaJs, FaReact, FaNode],
  },
  {
    img: GIFmatch,
    work: "Featured Project",
    title: "GIF Match Game",
    code: "https://github.com/ralphdaryn/GIFmatchgame",
    caption:
      "A memory card game reinvented with animated GIFS, offering the option to select categories for a personalized gaming experience powered by GIPHY API.",
    languages: [FaHtml5, FaCss3Alt, FaSass, FaJs],
  },
  {
    img: soleSearch,
    work: "Featured Project",
    title: "Sole Search",
    code: "https://github.com/ralphdaryn/sole-search",
    caption:
      "A sneaker database application providing extensive details about various sneaker models, brands, release dates and current market prices of your favourite brands using the Zylalabs API.",
    languages: [FaHtml5, FaCss3Alt, FaSass, FaJs, FaReact],
  },
];

const Projects = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide > 0 ? prevSlide - 1 : projectsData.length - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide < projectsData.length - 1 ? prevSlide + 1 : 0
    );
  };

  return (
    <div className="projects">
      <h2 className="projects__title">Latest Work</h2>
      <div className="projects__container">
        <button onClick={goToPrevSlide} className="projects__button">
          <FaChevronLeft />
        </button>
        <div className="projects__wrapper">
          {projectsData.map((project, index) =>
            index === currentSlide ? (
              <div key={index} className="projects__item">
                <img
                  className="projects__image"
                  src={project.img}
                  alt={project.title}
                />
                <div className="projects__content">
                  <p className="projects__work">{project.work}</p>
                  <h2 className="projects__text">{project.title}</h2>
                  <p className="projects__caption">{project.caption}</p>
                  <p className="projects__languages">
                    {project.languages.map((Language, langIndex) => (
                      <span key={langIndex}>
                        <Language />
                      </span>
                    ))}
                  </p>
                  <a
                    href={project.code}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="projects__code"
                  >
                    View Code <FaCodeBranch />
                  </a>
                </div>
              </div>
            ) : null
          )}
        </div>
        <button onClick={goToNextSlide} className="projects__button">
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Projects;
